<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
*/
-->
<template>
  <nerve-dialog v-model="dialogState" scrollable persistent width="500">
    <v-card class="confirm-dialog">
      <v-card-title>
        {{ $t(dialogParams.title) }}
      </v-card-title>
      <v-card-text>
        <v-row class="justify-center" no-gutters>
          {{ $t(dialogParams.msg) }}
        </v-row>
        <v-row class="justify-center mb-3">
          <v-checkbox
            id="iiotApiPermissionConfirmDialogCheckbox"
            ref="apiPermissionDialogCheckbox"
            v-model="dontShowPermissionDialog"
            :label="$t('access.roles.roleDetails.apiPermissionsDialogCheckbox')"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <nerve-button
          id="iiotApiPermissionConfirmDialogCancelButton"
          ref="apiPermissionDialogCancelButton"
          :text="$t('access.roles.roleDetails.apiPermissionsCancelAction')"
          type-of-btn="cancel"
          size="normal"
          class="mr-2"
          @click-event="close()"
        />
        <nerve-button
          id="iiotApiPermissionConfirmDialogConfirmButton"
          ref="apiPermissionDialogConfirmButton"
          :text="$t('access.roles.roleDetails.apiPermissionsConfirmAction')"
          type-of-btn="action"
          size="normal"
          @click-event="confirm()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from '@nerve/ui-components';

export default {
  name: 'ApiPermissionConfirmDialog',
  components: { NerveButton, NerveDialog },
  data: () => ({
    dontShowPermissionDialog: false,
  }),
  computed: {
    dialogState: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getApiPermissionConfirmDialogState']();
      },
      set() {
        this.close();
      },
    },
    dialogParams() {
      return this.$store.getters['utils/_api_request_handler/getApiPermissionConfirmDialogParams']();
    },
  },
  watch: {
    dontShowPermissionDialog(val) {
      this.dontShowPermissionDialog = val;
    },
  },
  methods: {
    async close() {
      if (this.dialogParams.cancelCallback) {
        await this.dialogParams.cancelCallback();
      }
      this.$store.dispatch('utils/_api_request_handler/close_api_permission_confirm');
    },
    async confirm() {
      try {
        this.$store.dispatch('roles/dont_show_dialog', this.dontShowPermissionDialog);
        await this.dialogParams.confirmCallback();
        this.$store.dispatch('utils/_api_request_handler/close_api_permission_confirm');
      } catch (e) {
        this.$log.debug(e);
      }
    },
  },
};
</script>
<style scoped>
.confirm-dialog {
  max-height: 460px !important;
  min-height: 320px !important;
}
</style>
