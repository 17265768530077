<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <nerve-dialog v-model="dialogState" scrollable persistent width="600">
    <v-card>
      <v-card-title class="text-center">
        {{ $t('nodes.deployedWorkloadControl.applyWorkloadConfigurationDialog.title') }}
        {{ dialogParams.title }}
      </v-card-title>
      <v-form
        id="iiotApplyWorkloadConfigurationDialogForm"
        ref="iiotApplyWorkloadConfigurationDialogForm"
        v-model="valid"
        class="pa-0 my-8"
        encrype="multipart/form-data"
        @keyup.enter.native="sendApplyConfigurationRequest"
      >
        <v-layout class="ml-3">
          <v-flex xs12 md4>
            <v-text-field
              id="iiotApplyWorkloadConfigurationDialogTheFileName"
              v-model="theFileName"
              :rules="theFileNameRule"
              type="text"
              disabled
              :placeholder="$t('nodes.deployedWorkloadControl.fileNamePlaceholder')"
              class="default-input pa-0 ml-4 mt-0"
              required
            />
          </v-flex>
          <v-flex xs12 md4 class="ml-7">
            <v-btn
              id="iiotApplyWorkloadConfigurationDialogUploadBtn"
              ref="iiotApplyWorkloadConfigurationDialogUploadBtn"
              :class="{ hidden: showRemovedBtn }"
              fab
              dark
              small
              primary
              @click="$refs.fileInput.click()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>

            <v-btn
              id="iiotApplyWorkloadConfigurationDialogRemoveConfigurationFileBtn"
              ref="iiotApplyWorkloadConfigurationDialogRemoveConfigurationFileBtn"
              :class="{ hidden: !showRemovedBtn }"
              fab
              dark
              small
              primary
              @click="removeConfigurationFileBtn"
            >
              <v-icon dark> mdi-minus </v-icon>
            </v-btn>
          </v-flex>

          <input
            id="fileInput"
            ref="fileInput"
            type="file"
            style="display: none"
            accept=".zip"
            @change="fileChangeHandler($event)"
            @click="$event.target.value = ''"
          />
        </v-layout>
      </v-form>
      <v-card-actions>
        <nerve-button
          id="iiotApplyWorkloadConfigurationDialogCancelButton"
          ref="applyWorkloadConfigurationDialogCancelButton"
          :text="$t('nodes.deployedWorkloadControl.applyWorkloadConfigurationDialog.cancelButton')"
          type-of-btn="cancel"
          size="normal"
          autofocus
          @click-event="close()"
        />
        <nerve-button
          id="iiotApplyWorkloadConfigurationDialogConfirmButton"
          ref="applyWorkloadConfigurationDialogConfirmButton"
          :disabled="!valid"
          :text="$t('nodes.deployedWorkloadControl.applyWorkloadConfigurationDialog.applyButton')"
          type-of-btn="action"
          size="normal"
          @click-event="sendApplyConfigurationRequest()"
        />
      </v-card-actions>
    </v-card>
  </nerve-dialog>
</template>

<script>
import { NerveButton, NerveDialog } from '@nerve/ui-components';

export default {
  name: 'ApplyWorkloadConfigurationDialog',
  components: { NerveButton, NerveDialog },
  data: () => ({
    isLoading: false,
    valid: false,
    showRemovedBtn: false,
    files: [],
    theFileNameRule: [(v) => !!v || ''],
  }),
  computed: {
    dialogState: {
      get() {
        return this.$store.getters['utils/_api_request_handler/getApplyWorkloadConfigurationDialogState']();
      },
      set() {
        this.close();
      },
    },
    dialogParams() {
      return this.$store.getters['utils/_api_request_handler/getApplyWorkloadConfigurationDialogParams']();
    },
    theFileName() {
      if (this.files.length > 0 && 'name' in this.files[0]) {
        return this.files[0].name;
      }
      return '';
    },
  },
  methods: {
    removeConfigurationFileBtn() {
      this.files = [];
      this.$refs.fileInput.value = null;
      this.showRemovedBtn = false;
    },
    fileChangeHandler(e) {
      this.files = e.target.files;
      this.showRemovedBtn = true;
    },
    close() {
      this.files = [];
      this.$refs.fileInput.value = null;
      this.showRemovedBtn = false;
      this.$store.dispatch('utils/_api_request_handler/close_apply_workload_configuration');
    },
    async sendApplyConfigurationRequest() {
      const workload = this.$store.getters['node-tree/getSelectedDeployedWorkload'];
      const formData = new FormData();
      formData.append('file', this.files[0]);
      let formParams;
      if (this.$store.getters['node-tree/getIsNewComposeSupported'] && workload.type === 'docker-compose') {
        const { configurationUpdateInfo } = this.dialogParams.service;
        formParams = {
          action: 'apply',
          service: configurationUpdateInfo.serviceName,
          volume: {
            name: configurationUpdateInfo.name,
            path: configurationUpdateInfo.path,
          },
          restartOnConfigurationUpdate: configurationUpdateInfo.restartOnConfigurationUpdate,
        };
      } else {
        formParams = {
          action: 'apply',
          volume: workload.configurationUpdateInfo.configurationVolumes[0],
          // eslint-disable-next-line max-len
          restartOnConfigurationUpdate: workload.configurationUpdateInfo.restartOnConfigurationUpdate,
        };
      }
      formParams.workloadId = workload.workloadId;
      formParams.versionId = workload.versionId;
      formData.append('data', JSON.stringify(formParams));
      try {
        this.$store.dispatch('utils/_api_request_handler/close_apply_workload_configuration');
        this.$store.dispatch('utils/_api_request_handler/show_loading_bar');
        await this.$store.dispatch('node-tree/apply_workload_configuration', formData);
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'nodes.deployedWorkloadControl.applyWorkloadConfigurationDialog.successMessage',
          color: 'success',
          showClose: true,
        });
        this.files = [];
        this.showRemovedBtn = false;
      } catch (err) {
        // No error handling here for request applied, interceptor
        // is handling errors and translations
      } finally {
        this.$store.dispatch('utils/_api_request_handler/close_loading_bar');
      }
    },
  },
};
</script>
<style lang="scss">
#iiotApplyWorkloadConfigurationDialogUploadBtn,
#iiotApplyWorkloadConfigurationDialogRemoveConfigurationFileBtn {
  @extend .primary-tttech-background-btn !optional;
}

#iiotApplyWorkloadConfigurationDialogRemoveConfigurationFileBtn.hidden,
#iiotApplyWorkloadConfigurationDialogUploadBtn.hidden {
  display: none;
}
</style>
